<div class="terms-container">
  <div class="header-legal">
      <h1>Política de privacidad</h1>
  </div>
  <div class="content-legal">
      <div class="content">
          <img src="../../../../assets/images/box_brand.webp" alt="">
          <p><span>1. RESPONSABLE</span>
            <br><br>
            A. Datos de contacto del Responsable
            El responsable de los datos recabados mediante esta web es DENARIUS FINANCE,S.A. con direcciónAVENIDA DE GENERALPERÓN, 38, 4ª PLANTA (EDIFICIOMASTERS I), 28020,MADRID (MADRID) Y CIF A72771637. Usted podrá contactar con el responsable mediante el siguiente email dpo&#64;denariusfinance.com <br>
            B. Datos de contacto del Delegado de Protección de Datos
            dpo&#64;ingadeconnect.es
            <br><br>
            <span>2. FINALIDAD</span>
            <br><br>
            A. Descripción ampliada de los fines del tratamiento
            Gestión de clientes, contable, fiscal y administrativa
            Publicidad y prospección comercial
            Comercio electrónico
            Recepción de Currículums o solicitudes de empleo
            Gestión de solicitudes <br>
            B. Plazos o criterios de conservación de los datos
            Conservaremos los datos durante el tiempo que sea necesario para atender la solicitud y cumplir con las obligaciones legales exigidas.
            Los datos que usted nos ha facilitado serán conservados mientras sean necesarios para las finalidades indicadas en el punto 2.A., o también serán almacenadas por el tiempo exigido por distintas legislaciones aplicables. <br>
            C. Decisiones automatizadas, perfiles y lógica aplicada
            Los datos recogidos mediante la página web no serán utilizados para tomar decisiones automatizadas.
            <br><br>
            <span>3. LEGITIMACION</span>
            <br><br>
            A. Legislación aplicable
            La legislación aplicada es el Reglamento General de Protección de Datos 2016/679 de 27 de abril de 2016 así como la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales. <br>
            B. Detalles de la base jurídica del tratamiento, en los casos de obligación legal, interés público o interés público o interés legítimo
            Art. 6, apartado 1 letra a. Que considera el consentimiento del interesado como una base legítima para el tratamiento de datos personales contenidos en este fichero. <br>
            Art. 6, apartado 1 letra b. Que considera la ejecución de un contrato como una base legítima para el tratamiento de datos personales contenidos en este fichero. <br>
            Art. 6, apartado 1 letra c. Que considerala existencia de una obligación legal que prevalezca constituye una base legítima para el tratamiento de datos personales contenidos en este fichero. <br>
            C. Obligación o no de facilitar datos y consecuencias de no hacerlo <br>
            No está obligado a facilitarnos los datos, sin embargo, será necesario para poder gestionar las sugerencias o peticiones que necesite hacernos mediante la página web.
            <br><br>
            <span>4. DESTINATARIOS</span>
            <br><br>
            A. Destinatarios <br>
            Organizaciones o personas directamente relacionadas con la empresa, Administración pública con competencia en la materia. <br>
            B. Destinatarios específicos <br>
            Encargados de tratamiento podrán acceder a los datos facilitados mediante esta página web por el ejercicio de sus funciones de mantenimiento y prestar el servicio solicitado. <br>
            C. Decisiones de adecuación, garantías, normas corporativas vinculantes o situaciones específicas aplicables <br>
            No existen. <br>
            D. Transferencias internacionales <br>
            No existen.
            <br><br>
            <span>5. DERECHOS</span>
            <br><br>
            A. Cómo ejercer los derechos de acceso, rectificación, supresión y portabilidad de sus datos, y la limitación u oposición a su tratamiento
            El interesado podrá conocer, modificar, o eliminar los datos que la empresa posea acerca de su persona mediante correoelectrónico o bien por correopostal a AVENIDADE GENERAL PERÓN, 38, 4ª PLANTA (EDIFICIO MASTERSI), 28020, MADRID ( MADRID). El interesado podrá transferir sus datos a otra entidad o bien limitar los ya existentes que la empresa posea acerca de su persona mediantecorreo electrónico o bienpor correo postal a AVENIDA DE GENERAL PERÓN, 38, 4ª PLANTA (EDIFICIO MASTERS I), 28020, MADRID (MADRID). <br>
            B. Derecho a retirarel Consentimiento prestado <br>
            El interesado podrá retirar los datos que la empresa posee acerca de su persona mediante correo electrónico o bien por correo postal a AVENIDA DE GENERAL PERÓN, 38, 4ª PLANTA (EDIFICIO MASTERS I), 28020, MADRID (MADRID). <br>
            C. Derecho a reclamar ante la Autoridad de Control <br>
            El interesado podrá reclamar ante la Agencia Española de Protección de Datos acerca de toda incidencia que pueda surgirle.
            <br><br>
            <span>6. PROCEDENCIA</span>
            <br><br>
            A. Información detallada del origen de los datos
            Facilitados por el propio interesado <br>
            B. Categorías de datos que se tratan <br>
            Nombre y Apellidos <br>
            NIF/DNI/NIE <br>
            Dirección <br>
            Imagen <br>
            Teléfono <br>
            Firma <br>
            Email<br> 
            Características personales <br>
            Detalles del empleo <br>
            Económicos, financieros y de seguros  <br>
            Transacciones de bienes y servicios</p>
      </div>
  </div>
</div>