<div class="terms-container">
    <div class="header-legal">
        <h1>Aviso Legal</h1>
    </div>
    <div class="content-legal">
        <div class="content">
            <img src="../../../../assets/images/box_brand.webp" alt="">
            <p>DENARIUS FINANCE,S.A. (en adelante“la empresa”), con domicilio social en CALLE VELAZQUEZ 27, 1, EXT IZD,28001, MADRID (MADRID), y con CIF A72771637, inscritaen el Registro Mercantil de Madrid, tomo 44.464, libro 0, folio 151, sección 8, hoja M-783.452,
                <br><br>
               <span>INFORMA:</span> 
               <br><br>
                La utilización del nombre de dominio www.denarius.es se encuentra debidamente registrado por la empresa, con todas las garantías, tal y como dispone en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico. No obstante, se pone de manifiesto la plena adecuación de los presentes Términos Legales a la normativa vigente en materia de Protección de Datos, Comercio Electrónico, Condiciones Contratación, Propiedad Intelectual y demás disposiciones subsidiarias.
                <br><br>
                <span>1. ACEPTACIÓN DE LOS TÉRMINOS LEGALES</span>
                <br><br>
                El acceso a este sitio web o su utilización en cualquier forma implica la aceptación de todas y cada una de los presentes Términos Legales, reservándose la empresa el derecho a modificarlos en cualquier momento. En consecuencia, será responsabilidad de todo visitante y/o Usuario, la atenta lectura de los Términos Legales de uso vigente en cada una de las ocasiones en que acceda a este sitio web, por lo que, si éste no está de acuerdo con cualquiera de los mismos aquí dispuestos, deberá abstenerse respecto al uso de la presente página web.
                <br><br>
                <span>2. OBJETO</span>
                <br><br>
                Por medio de la web www.denarius.es, se facilita a los Usuarios el acceso a diversos contenidos, servicios, información y datos (los "contenidos"), puestos a su disposición. La empresa se reserva el derecho de modificar en cualquier momento la presentación, configuración y localización de la página web, así como los contenidos, productos y servicios en él dispuestos.
                <br><br>
                <span>3. CONDICIONES DE ACCESO</span>
                <br><br>
                A. El acceso a la información de los distintos productos y servicios existentes en el sitio web, así como a su navegación será libre y gratuita no exigiéndose por tanto a los Usuarios el pertinente registro con la consecuente entrega de sus datos personales, ni la utilización de claves o contraseñas.
                B. Cuando para el acceso a determinados contenidos o servicios sea necesario facilitar datos de carácter personal, los Usuarios garantizarán su veracidad, exactitud, autenticidad y vigencia. La empresa, dará a dichos datos el tratamiento automatizado que corresponda en función de su naturaleza o finalidad, en los términos indicados en la sección de Política de Protección de Datos.
                <br><br>
                <span>4. CONDICIONES DE UTILIZACIÓN</span>
                <br><br>
                A. El Usuario se compromete a hacer un uso adecuado y lícito del sitio web así como de los contenidos y servicios, de conformidad con la legislación aplicable en cada momento, los Términos Legales del sitio web, la moral y buenas costumbres generalmente aceptadas y el orden público.El Usuario deberá abstenerse de:
                Hacer un uso no autorizado o fraudulento del sitio web y/o de los contenidos con fines o efectos ilícitos, prohibidos en los presentes Términos Legales, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de los servicios o los documentos, archivos y toda clase de contenidos almacenados en cualquier equipo informático;
                Acceder o intentar acceder a recursos o áreas restringidas del sitio web, sin cumplir las condiciones exigidas para dicho acceso;
                Provocar daños en los sistemas físicos o lógicos del sitio web, de sus proveedores o de terceros;
                Introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar daños en los sistemas físicos o lógicos de la empresa, de sus proveedores o de terceros;
                Intentar acceder, utilizar y/o manipular los datos de la empresa, terceros proveedores y otros Usuarios;
                Reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación pública,transformar o modificarlos contenidos, a menos que se cuente con la autorización expresa del titular de los correspondientes derechos o ello resulte legalmente permitido;
                Suprimir, ocultar o manipular las notas sobre derechos de propiedad intelectual o industrial y demás datos identificativos de los derechos de la empresa o de terceros incorporados a los contenidos, así como los dispositivos técnicos de protección o cualesquiera mecanismos de información que puedan insertarse en los contenidos;
                intentar obtener datos personales distintos a los que está autorizado a conocer, empleando para ello medios o procedimientos ilícitos, fraudulentos o que puedan causar cualquier tipo de daño. (Véase Virus, troyanos, bug's, Worms, etc);
                En particular, y a título meramente indicativo y no exhaustivo, el Usuario se compromete a no transmitir, difundir o poner a disposición de terceros informaciones, datos, contenidos, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software y, en general,cualquier clase de material que:
                De cualquier forma sea contrario, menosprecie o atente contra los derechos fundamentales y las libertades públicas reconocidas constitucionalmente, en los Tratados Internacionales y en el resto de la legislación;
                Induzca, incite o promueva actuaciones delictivas, denigratorias, difamatorias, violentas o, en general, contrarias a la ley, a la moral, a las buenas costumbres generalmente aceptadas o al orden público;
                Induzca, incite o promueva actuaciones, actitudes o pensamientos discriminatorios por razón de sexo, raza, religión, creencias, edad o condición;
                Incorpore, ponga a disposición o permita acceder a productos, elementos, mensajes y/o servicios delictivos, violentos, ofensivos, nocivos, degradantes o, en general, contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden público;
                Induzca o puedainducir a un estado inaceptable de ansiedad o temor;
                Induzca o incite a involucrarse en prácticas peligrosas, de riesgo o nocivas para la salud y el equilibrio psíquico;
                Se encuentra protegido por la legislación en materia de protección intelectual o industrial perteneciente a la empresa o a terceros sin que haya sido autorizado el uso que se pretenda hacer;
                Sea contrario al honor, a la intimidad personal y familiar o a la propia imagen de las personas;
                Constituya cualquier tipo de publicidad;
                Incluya cualquier tipo de virus o programa que impida el normal funcionamiento del sitio web.
                <br>
                B. Cuando para el acceso a determinados contenidos o servicios sea necesario facilitar datos de carácter personal, los Usuarios garantizarán su veracidad, exactitud, autenticidad y vigencia. La empresa, dará a dichos datos el tratamiento automatizado que corresponda en función de su naturaleza o finalidad, en los términos indicados en la sección de Política de Protección de Datos.
                <br><br>
                <span>5. RESPONSABILIDADES</span>
                <br><br>
                A. La empresa no garantiza el acceso continuado, ni la correcta visualización, descarga o utilidad de los elementose informaciones contenidas en las páginasde la empresa, que pueden verse impedidos, dificultados o interrumpidos por factores o circunstancias que están fuera de su control <br>
                B. La empresa, podrá interrumpir el servicio o resolver de modo inmediato la relación con el Usuario si detecta un uso de su Portalo de cualquiera de los servicios ofertados en el mismoson contrarios a los presentes Términos Legales. <br>
                C. La empresa, pone a disposición de los Usuarios una dirección de correo electrónico dpo&#64;denariusfinance.com para que cualquier contenido que pueda afectar a la actividad de otros usuariossea puesto de manifiesto, con la voluntadde rectificar el mismo en caso de ser apropiado.<br>
                D. La empresa, no se hace responsable por daños, perjuicios, pérdidas, reclamaciones o gastos, producidos por:<br>
                · Interferencias, interrupciones, fallos, omisiones, averías telefónicas, retrasos, bloqueos o desconexiones en el funcionamiento del sistema electrónico, motivadas por deficiencias, sobrecargas y errores en las líneas y redes de telecomunicaciones, o por cualquier otra causa ajena al control de La empresa;<br>
                · Intromisiones ilegítimas medianteel uso de programas malignosde cualquier tipo y a través de cualquier medio de comunicación, tales como virus informáticos o cualesquiera otros;<br>
                · Abuso indebido o inadecuado de las páginasweb de la empresa;<br>
                · Errores de seguridad o navegación producidos por un mal funcionamiento del navegador o por el uso de versiones no actualizadas del mismo. Los administradores de la empresa se reservan el derecho de retirar, total o parcialmente, cualquier contenido o información presente en la web.
                <br>
                E. La empresa, excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que pudieran deberse a la mala utilización de los servicios de libre disposición y uso por parte de los usuarios de la Web. Asimismo, la empresa queda exonerada de cualquier responsabilidad por el contenido e informaciones que puedan ser recibidas como consecuencia de los formularios de recogida de datos, estando los mismos únicamente para la prestación de los servicios descritos ofertados por la empresa; Servicios tales como comercio online y solicitud de presupuestos. Por otro lado, en caso de causar daños y perjuicios por un uso ilícito o incorrecto de dichos servicios, el usuario podrá ser reclamado por la empresa por dichos daños o perjuicios causados.
                <br><br>
                <span>6. PROPIEDAD INTELECTUAL E INDUSTRIAL</span>
                <br><br>
                A. El Usuario reconoce y acepta que todas las marcas, nombres comerciales o signos distintivos, todos los derechos de propiedad industrial e intelectual, sobre los contenidos y/o cualesquiera otros elementos insertados en el página, son propiedad exclusiva de la empresa y/o de terceros, quienes tiene el derecho exclusivo de utilizarlos en el tráfico económico. En ningún caso el acceso a la página Web implica ningún tipo de renuncia, transmisión, licencia o cesión total ni parcial de dichos derechos, salvo que se establezca expresamente lo contrario. Los presentes Términos Legales de la página Web no confieren a los Usuarios ningún otro derecho de utilización, alteración, explotación, reproducción, distribución o comunicación pública de la página Web y/o de sus Contenidos distintos de los aquí expresamente previstos. Cualquier otro uso o explotación de cualesquiera derechos estará sujeto a la previa y expresa autorización específicamente otorgada a tal efecto por la empresa o el tercero titular de los derechos afectados.<br>
                B. Los contenidos, textos, fotografías, diseños, logotipos, imágenes, programas de ordenador, códigos fuente y, en general, cualquier creación intelectual existente en este sitio, así como el propio sitio en su conjunto, como obra artística multimedia, están protegidos como derechos de autor por la legislación en materia de propiedad intelectual. La empresa es titular de los elementos que integran el diseño gráfico de su página web, lo menús, botones de navegación, el código HTML, los textos, imágenes, texturas, gráficos y cualquier otro contenido de la página Web o, en cualquier caso dispone de la correspondiente autorización para la utilización de dichos elementos. El contenido dispuesto en dicha web no podrá ser reproducido ni en todo ni en parte, ni transmitido, ni registrado por ningún sistema de recuperación de información, en ninguna forma ni en ningún medio, a menos que se cuente con la autorización previa, por escrito, de la citada Entidad.<br>
                C. Así mismo queda prohibido suprimir, eludir o manipular el "copyright" y la empresa, así como los dispositivos técnicos de protección, o cualesquiera mecanismos de información que pudieren contener los contenidos. El Usuario de este sitio Web se compromete a respetar los derechos enunciados y a evitar cualquier actuación que pudiera perjudicarlos, reservándose en todo caso la empresa el ejercicio de cuantos medios o acciones legales le correspondan en defensa de sus legítimos derechos de propiedad intelectual e industrial.
                <br><br>
                <span>7. PROTECCIÓN DE DATOS</span>
                <br><br>
                Para utilizar algunos de los Servicios, los Usuarios deben proporcionar previamente ciertos datos de carácter personal. Para ello, la empresa tratará automatizadamente los Datos Personales en cumplimiento con el Reglamento General de Protección de Datos 2016/679de 27 de abril de 2016, Para ello, el usuario puede acceder a la política seguida en el tratamiento de los datos personales (POLITICA PROTECCION DE DATOS) así como el establecimiento de las finalidades previamente establecidas, a lo dispuesto en las condiciones definidas en la Política de Protección de Datos que presenta el Web.
                <br><br>
                <span>8. DURACIÓN Y TERMINACIÓN</span>
                <br><br>
                La prestación del servicio del presente sitio Web y los demás servicios tienen en principio una duración indefinida. No obstante, la empresa, podrá dar por terminada o suspender cualquiera de los servicios del portal. Cuandosea ello posible,la empresa, anunciará la terminación o suspensión de la prestación del servicio determinado
                <br><br>
                <span>9. FUERZA MAYOR</span>
                <br><br>
                La empresa, no será responsable en todo en caso de imposibilidad de prestar servicio, si ésta se debe a interrupciones prolongadas del suministro eléctrico, líneas de telecomunicaciones, conflictos sociales, huelgas, rebelión, explosiones, inundaciones, actos y omisiones del Gobierno, y en general todos los supuestos de fuerza mayor.
                <br><br>
                <span>10. COOKIES UTILIZADAS EN ESTE SITIO WEB</span>
                <br><br>
                Siguiendo las directrices de la Agencia Española de Protección de Datos podrá consultar nuestra Política de Cookies.
                <br><br>
                <span>11. LEY APLICABLE Y JURISDICCIÓN</span>
                <br><br>
                A. Los presentes Términos Legales se rigen por la Ley española. En la medida en que así lo permita la ley, las partes con renuncia expresa a cualquier otro fuero que pudiera corresponderles, acuerdan someterse a la jurisdicción de los Juzgados y Tribunales donde recae la razón social de la empresa.</p>
        </div>
        
    </div>
  </div>